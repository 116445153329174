import BHTlogo from "../assests/bht/055555555555555.png";
import emailLogo from "../assests/bht/mailbox-svgrepo-com.png";
import wedsiteLogo from "../assests/bht/world-wide-web-svgrepo-com (1).png";
import addressLogo from "../assests/bht/Path 184.png";
import detailslogo from "../assests/bht/hindu-temple-svgrepo-com.png";
import jsPDF from "jspdf";
import moment from "moment/moment";
import axios from "axios";

const generatePDF = async (props) => {
  console.log("propss", props);
  console.log("propsof0", props[0]);
  console.log("generate", `${props[0].pdf}`);
  const consentDataShared = props[0].ConsentDataShared;
  const consentImageUsed = props[0].ConsentImageUsed;
  const parentalGuardianConsent = props[0].ParentalGuardianConsent;

  const imageBase64 = props[0]?.MemberSignature;
  var date = moment(props[0]?.Date);
  var formattedDate = date.format("YYYY/MM/DD");

  const customPageSize = { width: 269.143, height: 380 };

  // Calculate new dimensions by decreasing both width and height by 10%
  const newWidth = customPageSize.width * 0.9;
  const newHeight = customPageSize.height * 0.9;

  // Create a new instance of jsPDF with the updated custom page size
  const doc = new jsPDF({
    orientation: "portrait", // or 'landscape' for landscape orientation
    unit: "mm", // units: 'mm', 'cm', 'in', or 'px'
    format: [newWidth, newHeight], // Use the updated custom page size
  });

  // Function to draw a checkbox
  const drawCheckbox = (x, y, isChecked) => {

    const checkboxStyle = {
      width: 5,
      height: 5,
      border: "2px solid #006089",
    };

    // Draw the custom-styled checkbox border
    doc.setDrawColor(0);
    doc.setLineWidth(0.1);

    // Set additional properties including the class
    doc.setProperties({
      class: "form-check-input", // Add the 'form-check-input' class
    });
    // Draw the checkbox border with custom styles
    doc.rect(x, y, checkboxStyle.width, checkboxStyle.height);

    if (isChecked === "Yes") {
      isChecked = true;
      // If isChecked is true, draw a checkmark (right tick)
      doc.setLineWidth(0.5); // Increase line width for the checkmark
      doc.line(
        x + 1,
        y + checkboxStyle.height / 2,
        x + checkboxStyle.width / 2 - 1,
        y + checkboxStyle.height - 1
      );
      doc.line(
        x + checkboxStyle.width / 2 - 1,
        y + checkboxStyle.height - 1,
        x + checkboxStyle.width - 1,
        y + 1
      );
    } else {
      // If isChecked is false, draw an 'X' mark
      isChecked = false;
      doc.setFontSize(12);
      doc.setTextColor("#000");
      doc.setFont("helvetica", "normal");
      doc.text("", x + 2, y + checkboxStyle.height - 2);
    }
  };

  const addCheckboxText = (text, x, y, isChecked) => {
    const checkboxStyle = {
      width: 5,
      height: 5,
      border: "2px solid #006089",
    };

    // Calculate the position for the text and checkbox
    const textX = x;
    const textY = y + checkboxStyle.height - 1;

    // Calculate the position for the checkbox
    const checkboxX = x + doc.getTextWidth(text) + 84.5; // Adjust the X position to move the checkbox to the right
    const checkboxY = y;

    // Draw the text
    doc.setFontSize(12);
    doc.setTextColor("#000");
    doc.setFont("helvetica", "normal");
    doc.text(text, textX, textY);

    // Draw the checkbox
    drawCheckbox(checkboxX, checkboxY, isChecked);
  };

  const addCheckboxText1 = (text, x, y, isChecked) => {
    const checkboxStyle = {
      width: 5,
      height: 5,
      border: "2px solid #006089",
    };

    // Calculate the position for the text and checkbox
    const textX = x;
    const textY = y + checkboxStyle.height - 1;

    // Calculate the position for the checkbox
    const checkboxX = x + doc.getTextWidth(text) + 61; // Adjust the X position to move the checkbox to the right
    const checkboxY = y;

    // Draw the text
    doc.setFontSize(12);
    doc.setTextColor("#000");
    doc.setFont("helvetica", "normal");
    doc.text(text, textX, textY);

    // Draw the checkbox
    drawCheckbox(checkboxX, checkboxY, isChecked);
  };
  const addCheckboxText2 = (text, x, y, isChecked) => {
    const checkboxStyle = {
      width: 5,
      height: 5,
      border: "2px solid #006089",
    };

    // Calculate the position for the text and checkbox
    const textX = x;
    const textY = y + checkboxStyle.height - 1;

    // Calculate the position for the checkbox
    const checkboxX = x + doc.getTextWidth(text) + 137; // Adjust the X position to move the checkbox to the right
    const checkboxY = y;

    // Draw the text
    doc.setFontSize(12);
    doc.setTextColor("#000");
    doc.setFont("helvetica", "normal");
    doc.text(text, textX, textY);

    // Draw the checkbox
    drawCheckbox(checkboxX, checkboxY, isChecked);
  };

  // Add content to the PDF
  // doc.text('Hello, World!', 10, 10);
  var x = 23;
  var y = 10;
  var width = 20;
  var height = 20;
  // Add the image to the PDF document
  doc.addImage(BHTlogo, "PNG", x, y, width, height);
  // Set the position and style of the text
  var textX = x + width + 10;
  var textY = y + height / 2;
  doc.setFontSize(18);
  doc.setTextColor("#1B8202");
  doc.setFont("helvetica", "bold");
  // Add the text to the PDF document
  doc.text("BEDFORD HINDU TEMPLE AND COMMUNITY TRUST", textX, textY);
  doc.setTextColor("#F6821F");
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text(
    "Consent to usage of personal information – under GDPR 2018  guidelines  Addendum to \n MEMBERSHIP APPLICATION FORM",
    39,
    35.5
  );
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Data Protection", 115, 47);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(
    "The information you provide in this form will be used solely for dealing with you as a member of  Bedford Hindu Temple\nand Community Trust (The Trust).",
    10,
    55
  );
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(
    "The Trust has a Data Privacy Policy which can be found at www.bedfordhindutemple.org. Your data will be stored  in\naccordance  with this policy.",
    10,
    70
  );
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(
    "The Trust may wish to publish a membership handbook, yearbook or directory. This would  include relevant member’s\nname, email addresses, phone/mobile number, and will be  available in either electronic or paper versions.",
    10,
    85
  );
  // Add checkboxes with your text
  const consentText =
    "If you consent to your data being shared in this way, please tick here.";
  const consentX = 10;
  const consentY = 95;
  const consentChecked = consentDataShared; // Change this value based on user input
  addCheckboxText(consentText, consentX, consentY, consentChecked);

  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(
    "Please be  aware that if you later decide to  withdraw consent  to your contact details  being  published  it will not be\npossible to remove  your  contact  details from  printed material until such time as the next edition of the handbook is\nprinted which will be on a [yearly] basis.",
    10,
    110
  );
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(
    "The Trust may arrange for photographs or videos to be taken of The Trust’s activities and published  on our website\nor social media channels for promotional purposes.",
    10,
    130
  );

  // Add checkboxes with your text
  const ConsentImageUsed =
    "If you consent to your image being used by The Trust in this way, please tick here.";
  const ConsentImageUsedX = 10;
  const ConsentImageUsedY = 140;
  const ConsentImageUsedChecked = consentImageUsed; // Change this value based on user input
  addCheckboxText1(
    ConsentImageUsed,
    ConsentImageUsedX,
    ConsentImageUsedY,
    ConsentImageUsedChecked
  );

  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(
    "PARENTAL/GUARDIAN CONSENT (if children under 18) If you  consent to the  use of your  child’s image being used",
    10,
    153
  );

  // Add checkboxes with your text
  const ParentalGuardianConsent2 = "for the purposes above, please tick here.";
  const ParentalGuardianConsentX2 = 10;
  const ParentalGuardianConsentY2 = 155;
  const ParentalGuardianConsentChecked2 = parentalGuardianConsent; // Change this value based on user input
  addCheckboxText2(
    ParentalGuardianConsent2,
    ParentalGuardianConsentX2,
    ParentalGuardianConsentY2,
    ParentalGuardianConsentChecked2
  );

  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(
    "If you or the individuals listed above later wish to withdraw consent, please contact Chairman/Vice-Chairman/Secretary\nof The Trust.",
    10,
    170
  );
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(
    "By  agreeing  to your  images being  used, you agree to  assign any  copyright or any  other right of ownership of these\nimages to The Trust.",
    10,
    185
  );
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");

  var textBoxWidth = 95;
  var textBoxHeight = 10;
  var textBoxMargin = 30;
  var textBoxX = 10;
  var textBoxY = 210;

  var label1 = "Member’s Name";
  var label2 = "Date";
  var text1 = `${props[0].MemberName}`;
  var text2 = `${formattedDate}`;
  var text3 = `${props[0].EmailId}`

  // First Name label and text box
  doc.text(textBoxX, textBoxY - 3, label1);
  doc.rect(textBoxX, textBoxY, textBoxWidth, textBoxHeight);
  doc.text(text1, textBoxX + 5, textBoxY + 5);

  // Last Name label and text box
  var textBox2X = textBoxX + textBoxWidth + textBoxMargin;
  doc.text(textBox2X, textBoxY - 3, label2);
  doc.rect(textBox2X, textBoxY, textBoxWidth, textBoxHeight);
  doc.text(text2, textBox2X + 5, textBoxY + 5);

  var label3 = "Member’s Email"
  var textBoxWidth = 95;
  var textBoxHeight = 10;
  var textBoxMargin = 30;
  var textBoxX = 10;
  var textBoxY = 235;

  // Last Name label and text box
  var textBox2Xx = textBoxX + textBoxWidth + textBoxMargin;
  doc.text(textBox2Xx, textBoxY - 3, label3);
  doc.rect(textBox2X, textBoxY, textBoxWidth, textBoxHeight);
  doc.text(text3, textBox2X + 5, textBoxY + 5);

  var textBoxWidth3 = 95;
  var textBoxHeight3 = 45;
  var textBoxMargin3 = 30;
  var textBoxX3 = 10;
  var textBoxY3 = 235;

  var label2 = "Signature";

  // var text3 = `${imageBase64}`;

  // Last Name label and text box
  doc.text(textBoxX3, textBoxY3 - 3, label2);
  doc.rect(textBoxX3, textBoxY3, textBoxWidth3, textBoxHeight3);
  // doc.text(text3, textBoxX3 + 5, textBoxY3 + 5);
  doc.addImage(imageBase64, "PNG", 35, 245, 20, 20);

  var backgroundColor = "#F0F0F0"; // Replace with your desired background color
  doc.setFillColor(backgroundColor);

  // Define the dimensions of the background section
  var sectionX = 0;
  var sectionY = 285;
  var sectionWidth = 255;
  var sectionHeight = 60;

  // Draw the background section
  doc.rect(sectionX, sectionY, sectionWidth, sectionHeight, "F");

  // Add content to the section
  doc.setFontSize(12);
  doc.setTextColor("#F6821F");
  doc.setFont("helvetica", "bold");
  doc.text("Contact Us", sectionX + 5, sectionY + 10);
  // Add the first image
  doc.addImage(wedsiteLogo, "PNG", 10, 300, 10, 10);
  // Add the first text
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("www.bedfordhindutemple.org", 30, 304);
  // Add the second image
  doc.addImage(emailLogo, "PNG", 115, 300, 10, 10);
  // Add the second text
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("bedfordhindutemple@gmail.com", 130, 304);
  // Add the first image
  doc.addImage(detailslogo, "PNG", 10, 315, 10, 10);
  // Add the first text
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("Registered Charity No. 1185539", 30, 321);
  // Add the second image
  doc.addImage(addressLogo, "PNG", 115, 315, 10, 10);
  // Add the second text
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("No. 5, Peel Street, Bedford MK40 2HX", 130, 321);

  // Save the PDF
  if (`${props[0].pdf}` === "yes") {
    doc.save(`${props[0].MemberName}_BHT_DataProtectionFrom.pdf`);
  }
  else {
    console.log("Condition is false. PDF not saved.");
  }
  // doc.save(`${props[0].MemberName}_BHT_DataProtectionFrom.pdf`);
  const pdfContent = doc.output("datauristring");
  console.log("pdfContent", pdfContent);
  window.localStorage.setItem("pdfContent", pdfContent);
  console.log("pdfffffffffffffff", window.localStorage.getItem("pdfContent"));
  const formData = {
    from: "bhtportal@gmail.com",
    to:[`${props[0].EmailId},bedfordhindutemple@gmail.com`],
    subject: `Thanks ${props[0].MemberName} from Bedford Hindu Temple`,
    html: `<p><span style="font-family: Helvetica;">Dear ${props[0].MemberName},</span></p>
      <p><span style="font-family: Helvetica;">Thank you for providing your consent to share the requested data in the Data Protection Form. Your privacy is important to us and we would like to assure you that we are committed to protecting the personal information shared by you. We appreciate your support in this Noble cause. &nbsp;</span></p>
      <p><span style="font-family: Helvetica;">To download the&nbsp;<span style="font-family: Helvetica;">Data Protection</span> Form, please find the attachment.</span></p>
      <p><span style="font-family: Helvetica;">Sincerely,&nbsp;</span></p>
      <p><span style="font-family: Helvetica;">Bedford Hindu Temple,</span></p>
      <p><span style="font-family: Helvetica;">Registered Charity No. 1185539,</span></p>
      <p><span style="font-family: Helvetica;">No. 5, Peel Street,&nbsp;</span></p>
      <p><span style="font-family: Helvetica;">Bedford MK40 2HX.</span></p>`,
    content: pdfContent.split(";base64,").pop(),
    filename: `${props[0].MemberName}_BHT_DataProtectionFrom.pdf`,
  };
  const res = await axios.post(
    "https://dev-api.bookyourtime.co.uk/emailAPI",
    formData
  );
  console.log("res", res);
};
const dataProtectionFromPdfGenerator = (data) => {
  console.log("pdfgenereator", data);
  return generatePDF(data);
};

export default dataProtectionFromPdfGenerator;
