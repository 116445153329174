import React, { useEffect, useRef, useState, useCallback } from "react";
import { makePostCall } from "../firebase/user.utils";
import classes from "./bhtform.module.css";
import { Link } from "react-router-dom";
import BackArrowImg from "../assests/backArrow.svg";
import { withRouter } from "react-router-dom";
import BHTlogo from "../assests/bht/055555555555555.png";
import Modal from "react-bootstrap/Modal";
import successPopUp from "../assests/bht/donate.png";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PdfGenerator from "./bhtPdf.component";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import { Typeahead } from "react-bootstrap-typeahead";
import { autocomplete } from "getaddress-autocomplete";
import Footer from "./footer";
import downloadicon from '../assests/downloadicon.png'

const BhtForm = () => {
  const initialState = {
    Title: "",
    FirstName: "",
    Surname: "",
    Email: "",
    ConfirmEmail: "",
    ContactNumber: "",
    Address: "",
    Postcode: "",
    Signature: "",
    CreationDate: "",
    ActiveFlag: "Y",
    Amount: "",
    showSuccessMessage: false,
    showErrorMessage: false,
  };
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [opencreateQuickLink, setOpencreateQuickLink] = useState(true);
  const [successChangePassword, setSuccessChangePassword] = useState(false);
  const [pdfconfirmation, setPdfconfirmation] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [Addresss, setAddresss] = useState("");
  const [formData, setFormData] = useState({});
  const [postcode, setPostcode] = useState("");
  const [address, setAddress] = useState([]);
  const [Addresss1, setAddresss1] = useState("");
  const [Addresss2, setAddresss2] = useState("");
  const [Addresss3, setAddresss3] = useState("");
  const [Addresss4, setAddresss4] = useState("");
  const [district, setDistrict] = useState("");
  const [country, setCountry] = useState("");
  const [signature, setSignature] = useState(""); // Manage signature directly in state
  const [isSignatureCleared, setIsSignatureCleared] = useState(false);
  const signRef = useRef(null);
  const [sign, setSign] = useState();


  let dataLoad = [];
  let suggData = [];

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const onCloseModal1 = () => {
    setSuccessChangePassword(false);
    setPdfconfirmation(false)
  };
  const handleYes = () => {
    const data = {
      Title: form.Title,
      FullName: form.FirstName,
      Surname: form.Surname,
      EmailId: form.Email,
      ConfirmEmailid: form.ConfirmEmail,
      ContactNumber: form.ContactNumber,
      Address: [
        Addresss1,
        Addresss2,
        Addresss3,
        Addresss4,
        district,
        country,
      ],
      Postcode: postcode,
      Signature: signature,
      CreationDate: selectedDate,
      ActiveFlag: "Y",
      Amount: form.Amount,
      pdf: "yes"
    };
    dataLoad.push(data);
    console.log("datalaod", dataLoad)
    setPdfconfirmation(false);
    PdfGenerator(dataLoad);
    setPostcode("");
    setAddress("");
    setAddresss1("");
    setAddresss2("");
    setAddresss3("");
    setAddresss4("");
    setDistrict("");
    setCountry("");
    setForm(initialState);
    // sign.clear();
    setAddresss("");
    setSuccessChangePassword(true);
    setSelectedDate("");
    signRef.current.clear();
    setSignature("");
    localStorage.removeItem("signature")
    setIsSignatureCleared(true);
  };

  const handleNo = () => {
    const data = {
      Title: form.Title,
      FullName: form.FirstName,
      Surname: form.Surname,
      EmailId: form.Email,
      ConfirmEmailid: form.ConfirmEmail,
      ContactNumber: form.ContactNumber,
      Address: [
        Addresss1,
        Addresss2,
        Addresss3,
        Addresss4,
        district,
        country,
      ],
      Postcode: postcode,
      Signature: signature,
      CreationDate: selectedDate,
      ActiveFlag: "Y",
      Amount: form.Amount,
      pdf: "no"
    };
    dataLoad.push(data);
    console.log("datalaod", dataLoad)
    setPdfconfirmation(false);
    PdfGenerator(dataLoad);
    setPostcode("");
    setAddress("");
    setAddresss1("");
    setAddresss2("");
    setAddresss3("");
    setAddresss4("");
    setDistrict("");
    setCountry("");
    setForm(initialState);
    // sign.clear();
    setAddresss("");
    setSuccessChangePassword(true);
    setSelectedDate("");
    signRef.current.clear();
    setSignature("");
    localStorage.removeItem("signature")
    setIsSignatureCleared(true);
  };
  const handleSignatureRef = useCallback((data) => {
    setSign(data);
  }, [setSign]);

  const handleClear = (event) => {
    event.preventDefault();
    if (signRef.current) {
      signRef.current.clear();
    }
    setSignature("");
    setIsSignatureCleared(true);
  };


  const handleGenerate = (event) => {
    event.preventDefault();
    const trimmedSignature = signRef.current.getTrimmedCanvas().toDataURL("image/png");
    setSignature(trimmedSignature);
    //setIsSignatureCleared(true); // Reset the flag
    localStorage.setItem("signature", trimmedSignature);
    setIsSignatureCleared(false) // Save signature to local storage
    return toast.success("Signature Saved Successfully.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    // Load the signature data from local storage when the component mounts
    const storedSignature = localStorage.getItem("signature");
    if (storedSignature) {
      setSignature(storedSignature);
    }

    // Add event listener for window resize
    const handleResize = () => {
      // Re-render the signature on resize
      if (signRef.current) {
        signRef.current.fromDataURL(signature);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, [signature]); // Re-run the effect when the signature changes

  const handlePostcodeChange = (event) => {
    setPostcode(event.target.value);
  };
  const validateForm = () => {
    const {
      Title,
      FirstName,
      Surname,
      Email,
      ConfirmEmail,
      ContactNumber,
      CreationDate,
      Amount,
      Signature,
    } = form;
    const newErrors = {};
    if (!Amount || Amount === "") {
      return toast.error("Please enter Amount.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!Title || Title === "") {
      return toast.error("Please enter Title.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!FirstName || FirstName === "") {
      return toast.error("Please enter FirstName.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!Surname || Surname === "") {
      return toast.error("Please enter Surname.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!Email || Email === "") {
      return toast.error("Please enter Email.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!Email.includes("@")) {
      return toast.error("Please enter valid Email.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!ConfirmEmail || ConfirmEmail === "") {
      return toast.error("Please enter ConfirmEmail.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!ConfirmEmail.includes("@")) {
      return toast.error("Please enter valid ConfirmEmail.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (Email !== ConfirmEmail) {
      return toast.error("Email Doesn't Match.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!ContactNumber || ContactNumber === "") {
      return toast.error("Please enter ContactNumber.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!postcode || postcode === "") {
      return toast.error("Please enter  Postcode.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!Addresss1 || Addresss1 === "") {
      return toast.error("Please enter  Address.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!district || district === "") {
      return toast.error("Please enter  county.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!country || country === "") {
      return toast.error("Please enter  country.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (!signature || signature === "") {
      return toast.error("Please enter Signature.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!selectedDate || selectedDate === "") {
      return toast.error("Please select Date.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    return newErrors;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormData(form);
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      const onSuccess = (data) => {
        if (data.Status === "Success") {
          setPdfconfirmation(true)
          setResult(data.data);

          setLoading(false);
          setOpencreateQuickLink(true);
        }
        if (data.Status !== "Success") {
          setErrorMessage(true);
        }
        setLoading(false);
      };

      const onFailure = (error) => {
        setErrorMessage(true);
        setLoading(false);
      };

      const data = {
        Title: form.Title,
        FullName: form.FirstName,
        Surname: form.Surname,
        EmailId: form.Email,
        ConfirmEmailid: form.ConfirmEmail,
        ContactNumber: form.ContactNumber,
        Address: [
          Addresss1,
          Addresss2,
          Addresss3,
          Addresss4,
          district,
          country,
        ],
        Postcode: postcode,
        Signature: signature,
        CreationDate: selectedDate,
        ActiveFlag: "Y",
        Amount: form.Amount,
      };
      dataLoad.push(data);
      try {
        makePostCall("/gac/gacBHT", data).then(onSuccess).catch(onFailure);
      } catch (error) {
        // form.reset();
        setForm(initialState);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    setAddress(address); // Check the value of address when it changes
  }, [address]);
  const selectedAddressField = async (selected) => {
    if (selected) {
      try {
        const response = await axios.get(
          `https://api.getAddress.io/get/${selected[0]?.id}?api-key=2aubcWJT7k2tZB8s-871FA40125`,
          {
            mode: "cors",
            headers: {
              // "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        suggData.push(response.data);
        setAddresss1(suggData[0]?.formatted_address[0]);
        setAddresss2(suggData[0]?.formatted_address[1]);
        setAddresss3(suggData[0]?.formatted_address[2]);
        setAddresss4(suggData[0]?.formatted_address[3]);
        setDistrict(suggData[0]?.county);
        setCountry(suggData[0]?.country);
      } catch (error) { }
    }
  };
  const handleAddressLookup = async (e) => {
    e.preventDefault();
    if (postcode === "") {
      return toast.error("Please enter  Postcode.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    try {
      const response = await axios.post(
        `https://api.getAddress.io/autocomplete/${postcode}?api-key=2aubcWJT7k2tZB8s-871FA40125`,
        {
          all: true,
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
          },
        }
      );
      if (response.data.suggestions.length === 0) {
        return toast.error(
          "Invalid PostCode or Cannot Find the Address. Please enter manually",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
      const data = response.data.suggestions;
      setAddress([...data]);
    } catch (error) { }
  };

  return (
    <div>
      <div className={classes.title}>
        <h5>
          <Link to={"/"} className={classes[`BHT-title`]}>
            <img src={BackArrowImg} alt="back-arrow-icon" height={15} />
            <span>back to Home</span>
          </Link>
        </h5>
      </div>
      {successChangePassword ? (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          onHide={onCloseModal1}
          contentClassName={classes[`success-modal`]}
        >
          <Modal.Header closeButton className={classes[`modal-header`]}>
            <Modal.Title></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={classes.resetpassword}>
              <div>
                <img
                  src={successPopUp}
                  alt="login-page-iage"
                  className={classes[`successchangepassword-icon`]}
                />
              </div>

              <span className={classes[`success-text`]}>Thank you!</span>
              <p className={classes[`success-modal-desc`]}>
                Donor Gift Aid Form submitted successfully.
              </p>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      {pdfconfirmation ? (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          onHide={onCloseModal1}
          contentClassName={classes[`success-modal`]}
        >
          <Modal.Header closeButton className={classes[`modal-header`]}>
          </Modal.Header>

          <Modal.Body>
            <div className={classes.resetpassword}>
              <div>
                <img
                  src={downloadicon}
                  alt="login-page-iage"
                  className={classes[`successchangepassword-icon`]}
                />
              </div>

              <span className={classes[`success-text`]}>Would you like to download the PDF?</span>
              {/* Add buttons for Yes and No */}
              <div className={classes[`button-container`]}>
                <button onClick={handleYes} className={classes[`confirm-btn`]}>Yes</button>
                <button onClick={handleNo} className={classes[`confirm-btn`]}>No</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      <Form>
        <div className={classes.formWrapper}>
          <div className={classes.bhtform}>
            <div className={classes.header}>
              <div className={classes.logo}>
                <img src={BHTlogo} />
              </div>
              <h1 className={classes.heading}>
                BEDFORD HINDU TEMPLE AND COMMUNITY TRUST
              </h1>
            </div>
            <div>
              <div className={classes.para}>
                <p>Charity Gift Aid Declaration - multiple donation</p>
              </div>
              <div className={classes.content}>
                <p>
                  Boost your donation by 25p of Gift Aid for every £ 1 you
                  donate Gift Aid is reclaimed by the charity from the tax you
                  pay for the current tax year. Your address is needed to
                  identify you as a current UK taxpayer.
                </p>
              </div>
              <div className={classes.sideHeading}>
                <p>
                  In order to Gift Aid your donation you must tick the box
                  below:
                </p>
              </div>
              <div className={classes.paraInput}>
                <p>
                  {" "}
                  <input type="checkbox" name="" id="" checked />I want to Gift
                  Aid my donation of £{" "}
                  <input
                    type="text"
                    placeholder="Amount"
                    value={form.Amount}
                    onChange={(e) => setField("Amount", e.target.value)}
                    style={{ paddingLeft: "10px" }}
                  />{" "}
                  and any donations I make in the future or have made in the
                  past 4 years to:
                </p>
              </div>
              <div className={!!errors.Amount && classes.error}>
                {" "}
                {errors.Amount}
              </div>
              <div className={classes.declarationTitle}>
                <p>BEDFORD HINDU TEMPLE AND COMMUNITY TRUST</p>
              </div>
              <div className={classes.paraInput}>
                <p>
                  I am a UK taxpayer and understand that if I pay less Income
                  Tax and/or Capital Gains Tax than the amount of Gift Aid
                  claimed on all my donations in that tax year it is my
                  responsibility to pay any difference.
                </p>
              </div>
              <div className={classes.para}>
                <p>My Details</p>
              </div>
              <div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="title">
                      Title
                    </label>
                    <br></br>
                    <select
                      placeholder="Title"
                      name="title"
                      value={form.Title}
                      onChange={(e) => setField("Title", e.target.value)}
                      style={{ width: "100%", height: "35px" }}
                    >
                      <option value=""></option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                    </select>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="firstname">
                      First name or initial(s)
                    </label>
                    <br></br>
                    <input
                      type="text"
                      placeholder="First name or initial(s)"
                      name="firstname"
                      value={form.FirstName}
                      onChange={(e) => setField("FirstName", e.target.value)}
                      className={classes.formInput}
                    />
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="surname">
                      Surname
                    </label>
                    <br></br>
                    <input
                      type="text"
                      placeholder="Surname"
                      name="surname"
                      value={form.Surname}
                      onChange={(e) => setField("Surname", e.target.value)}
                      className={classes.formInput}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="Email">
                      Email
                    </label>
                    <br></br>
                    <input
                      type="email"
                      placeholder="Email"
                      name="Email"
                      value={form.Email}
                      onChange={(e) => setField("Email", e.target.value)}
                      className={classes.formInput}
                    />
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="ConfirmEmail">
                      Confirm Email
                    </label>
                    <br></br>
                    <input
                      type="email"
                      placeholder="Confirm Email"
                      name="ConfirmEmail"
                      value={form.ConfirmEmail}
                      onPaste={(e) => e.preventDefault()}
                      onChange={(e) => setField("ConfirmEmail", e.target.value)}
                      className={classes.formInput}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="ContactNumber">
                      Contact Number
                    </label>
                    <br></br>
                    <input
                      type="text"
                      maxLength="14"
                      placeholder="Contact Number"
                      name="ContactNumber"
                      value={form.ContactNumber}
                      onChange={(e) =>
                        setField("ContactNumber", e.target.value?.replace(/[^0-9]/g, ''))
                      }
                      className={classes.formInput}
                    />
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="Address">
                      PostCode
                    </label>
                    <br></br>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        placeholder="PostCode"
                        value={postcode}
                        onChange={handlePostcodeChange}
                        className={classes.formInput}
                      />
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "30%",
                          border: "2px solid black",
                          backgroundColor: "#f0f0f0",
                          height: "35px",
                          fontSize: "10px",
                          whiteSpace: "wrap",
                          padding: " 0",
                          cursor: "pointer",
                        }}
                        onClick={handleAddressLookup}
                      >
                        Find Address
                      </span>
                    </div>
                    {address.length > 0 && (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="address"
                        onChange={selectedAddressField}
                        options={address}
                        placeholder="Please select Address"
                      />
                    )}
                  </div>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput}>Address1</label>
                    <br></br>
                    <div>
                      <input
                        value={Addresss1}
                        onChange={(e) => setAddresss1(e.target.value)}
                        type="text"
                        className={classes.formInput}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label style={{ fontWeight: 600 }} htmlFor="Address2">
                      Address2
                    </label>
                    <br></br>
                    <div>
                      <input
                        value={Addresss2}
                        onChange={(e) => {
                          setAddresss2(e.target.value);
                          setAddresss3(e.target.value);
                        }}
                        type="text"
                        className={classes.formInput}
                      />
                    </div>
                  </div>

                  <div className={classes.inputWrapper}>
                    <label style={{ fontWeight: 600 }} htmlFor="Address4">
                      Address3
                    </label>
                    <br></br>
                    <div>
                      <input
                        value={Addresss4}
                        onChange={(e) => setAddresss4(e.target.value)}
                        type="text"
                        className={classes.formInput}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="county">
                      County
                    </label>
                    <br></br>
                    <div>
                      <input
                        value={district}
                        onChange={(e) => setDistrict(e.target.value)}
                        type="text"
                        className={classes.formInput}
                      />
                    </div>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="country">
                      Country
                    </label>
                    <br></br>
                    <div>
                      <input
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        type="text"
                        className={classes.formInput}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput}>Signature</label>
                    <br></br>
                    <div style={{ border: "1px solid black", height: 200 }}>
                      <SignatureCanvas
                        canvasProps={{ className: "signature-pad" }}
                        // ref={(data) => setSign(data)}
                        ref={(ref) => (signRef.current = ref)}
                      />
                      <div className={classes.btngroup}>
                        <button name="clear" onClick={handleClear}>
                          Clear
                        </button>
                        <button name="save" onClick={handleGenerate}>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label className={classes.fgInput} htmlFor="dateInput">
                      Date
                    </label>
                    <br></br>
                    <div style={{ width: "100%" }}>
                      <DatePicker
                        id="dateInput"
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                        style={{
                          width: "100%",
                          height: "35px",
                          padding: "10px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.sideHeading}>
                <p>Please notify the charity if you</p>
              </div>
              <div className={classes.paraInput}>
                <ul>
                  <li>Want to cancel this declaration</li>
                </ul>
                <ul>
                  <li>Change your name or home address</li>
                </ul>
                <ul>
                  <li>
                    No longer pay sufficient tax on your income and/or capital
                    gains
                  </li>
                </ul>
              </div>
              <div className={classes.paraInput2}>
                <p>
                  If you pay Income Tax at the higher or additional rate and
                  want to receive the additional tax relief due to you, you must
                  include all your Gift Aid donations on your Self-Assessment
                  tax return or ask HM Revenue and Customs to adjust your tax
                  code
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <div className={classes.submit}>
          <button
            name="submit"
            type="submit"
            onClick={handleSubmit}
            className={classes[`submit-btn`]}
          >
            Submit
          </button>
        </div>
      </Form>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default withRouter(BhtForm);
