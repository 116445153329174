import React, { useState, useEffect } from "react";
import BHTlogo from "../assests/bht/055555555555555.png";
import emailLogo from "../assests/bht/mailbox-svgrepo-com.png";
import wedsiteLogo from "../assests/bht/world-wide-web-svgrepo-com (1).png";
import addressLogo from "../assests/bht/Path 184.png";
import detailslogo from "../assests/bht/hindu-temple-svgrepo-com.png";
import jsPDF from "jspdf";
import moment from "moment/moment";
import axios from "axios";
import fs from "file-saver";

const generatePDF = async (props) => {
  console.log("propss", props);
  console.log("propsof0", props[0]);
  console.log("generate",`${props[0].pdf}`);
  const imageBase64 = props[0].Signature;
  var date = moment(props[0].CreationDate);
  var formattedDate = date.format("YYYY/MM/DD");
  // Create a new instance of jsPDF
  const doc = new jsPDF();

  // Add content to the PDF
  // doc.text('Hello, World!', 10, 10);
  var x = 10;
  var y = 10;
  var width = 20;
  var height = 20;
  // Add the image to the PDF document
  doc.addImage(BHTlogo, "PNG", x, y, width, height);
  // Set the position and style of the text
  var textX = x + width + 10;
  var textY = y + height / 2;
  doc.setFontSize(18);
  doc.setTextColor("#1B8202");
  doc.setFont("helvetica", "bold");
  // Add the text to the PDF document
  doc.text("BEDFORD HINDU TEMPLE AND COMMUNITY TRUST", textX, textY);
  doc.setTextColor("#F6821F");
  doc.setFontSize(14);
  doc.setFont("helvetica", "bold");
  doc.text("Charity Gift Aid Declaration - multiple donation", 10, 40);
  doc.rect(10, 45, 190, 20, "S");
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(
    "Boost your donation by 25p of Gift Aid for every £ 1 you donate Gift Aid is reclaimed by the \n charity from the tax you pay for the current tax year. Your address is needed to identify you as a \n current UK taxpayer.",
    11,
    50,
    { align: "left" }
  );
  doc.setFontSize(14);
  doc.setTextColor("#F6821F");
  doc.setFont("helvetica", "bold");
  doc.text(
    "In order to Gift Aid your donation you must tick the box below:",
    10,
    75
  );
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(
    `I want to Gift Aid my donation of £ ${props[0].Amount} and any donations I make in the future or have made \n in the past 4 years to:`,
    10,
    85
  );
  doc.setFontSize(14);
  doc.setTextColor("#1B8202");
  doc.setFont("helvetica", "bold");
  doc.text("BEDFORD HINDU TEMPLE AND COMMUNITY TRUST", 35, 100);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text(
    "I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax than the \n amount of Gift Aid claimed on all my donations in that tax year it is my responsibility to pay \n any difference.",
    10,
    110
  );
  doc.setFontSize(14);
  doc.setTextColor("#F6821F");
  doc.setFont("helvetica", "bold");
  doc.text("My Details", 10, 130);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  var textBoxWidth = 80;
  var textBoxHeight = 10;
  var textBoxMargin = 30;
  var textBoxX = 10;
  var textBoxY = 150;

  var label1 = "Title";
  var label2 = "First name or initial(s)";
  var text1 = `${props[0].Title}`;
  var text2 = `${props[0].FullName}`;

  // First Name label and text box
  doc.text(textBoxX, textBoxY - 6, label1);
  doc.rect(textBoxX, textBoxY, textBoxWidth, textBoxHeight);
  doc.text(text1, textBoxX + 5, textBoxY + 5);

  // Last Name label and text box
  var textBox2X = textBoxX + textBoxWidth + textBoxMargin;
  doc.text(textBox2X, textBoxY - 6, label2);
  doc.rect(textBox2X, textBoxY, textBoxWidth, textBoxHeight);
  doc.text(text2, textBox2X + 5, textBoxY + 5);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  var textBoxWidth1 = 80;
  var textBoxHeight1 = 10;
  var textBoxMargin1 = 30;
  var textBoxX1 = 10;
  var textBoxY1 = 175;

  var label1 = "Surname";
  var label2 = "Email";
  var text1 = `${props[0].Surname}`;
  var text2 = `${props[0].EmailId}`;

  // First Name label and text box
  doc.text(textBoxX1, textBoxY1 - 6, label1);
  doc.rect(textBoxX1, textBoxY1, textBoxWidth1, textBoxHeight1);
  doc.text(text1, textBoxX1 + 5, textBoxY1 + 5);

  // Last Name label and text box
  var textBox2X = textBoxX1 + textBoxWidth1 + textBoxMargin1;
  doc.text(textBox2X, textBoxY1 - 6, label2);
  doc.rect(textBox2X, textBoxY1, textBoxWidth1, textBoxHeight1);
  doc.text(text2, textBox2X + 5, textBoxY1 + 5);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  var textBoxWidth2 = 80;
  var textBoxHeight2 = 10;
  var textBoxMargin2 = 30;
  var textBoxX2 = 10;
  var textBoxY2 = 205;

  var label1 = "ContactNumber";
  var label2 = "PostCode";
  var text1 = `${props[0].ContactNumber}`;
  var text2 = `${props[0].Postcode}`;

  // First Name label and text box
  doc.text(textBoxX2, textBoxY2 - 6, label1);
  doc.rect(textBoxX2, textBoxY2, textBoxWidth2, textBoxHeight2);
  doc.text(text1, textBoxX2 + 5, textBoxY2 + 5);

  // Last Name label and text box
  var textBox2X = textBoxX2 + textBoxWidth2 + textBoxMargin2;
  doc.text(textBox2X, textBoxY2 - 6, label2);
  doc.rect(textBox2X, textBoxY2, textBoxWidth2, textBoxHeight2);
  doc.text(text2, textBox2X + 5, textBoxY2 + 5);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  var textBoxWidth3 = 80;
  var textBoxHeight3 = 30;
  var textBoxMargin3 = 30;
  var textBoxX3 = 10;
  var textBoxY3 = 235;

  var label1 = "Address";
  var label2 = "Signature";
  var address = props[0].Address;
  var completeAddress = address.join("\n");
  var text1 = `${completeAddress}`;
  // var text2 = `signature`;

  // First Name label and text box
  doc.text(textBoxX3, textBoxY3 - 6, label1);
  doc.rect(textBoxX3, textBoxY3, textBoxWidth3, textBoxHeight3);
  doc.text(text1, textBoxX3 + 5, textBoxY3 + 5);

  // Last Name label and text box
  var textBox2X = textBoxX3 + textBoxWidth3 + textBoxMargin3;
  doc.text(textBox2X, textBoxY3 - 6, label2);
  doc.rect(textBox2X, textBoxY3, textBoxWidth3, textBoxHeight3);
  doc.addImage(imageBase64, "PNG", 130, 240, 20, 20);
  // doc.text(text2, textBox2X + 5, textBoxY3 + 5);
  var textBoxWidth2 = 80;
  var textBoxHeight2 = 10;
  var textBoxMargin2 = 30;
  var textBoxX2 = 10;
  var textBoxY2 = 280;

  var label1 = "Date";
  var text1 = `${formattedDate}`;
  // First Name label and text box
  doc.text(textBoxX2, textBoxY2 - 6, label1);
  doc.rect(textBoxX2, textBoxY2, textBoxWidth2, textBoxHeight2);
  doc.text(text1, textBoxX2 + 5, textBoxY2 + 5);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.addPage();
  doc.setFontSize(14);
  doc.setTextColor("#F6821F");
  doc.setFont("helvetica", "bold");
  doc.text("Please notify the charity if you", 10, 20);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.setFont("helvetica", "normal");
  doc.text("• Want to cancel this declaration", 10, 28);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.text("• Change your name or home address", 10, 36);
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.text(
    "• No longer pay sufficient tax on your income and/or capital gains",
    10,
    44
  );
  doc.setFontSize(12);
  doc.setTextColor("#000");
  doc.text(
    "If you pay Income Tax at the higher or additional rate and want to receive the additional tax relief \n due to you, you must include all your Gift Aid donations on your Self-Assessment tax return or ask \n HM Revenue and Customs to adjust your tax code",
    10,
    55
  );
  var backgroundColor = "#F0F0F0"; // Replace with your desired background color
  doc.setFillColor(backgroundColor);

  // Define the dimensions of the background section
  var sectionX = 0;
  var sectionY = 80;
  var sectionWidth = 220;
  var sectionHeight = 60;

  // Draw the background section
  doc.rect(sectionX, sectionY, sectionWidth, sectionHeight, "F");

  // Add content to the section
  doc.setFontSize(12);
  doc.setTextColor("#F6821F");
  doc.setFont("helvetica", "bold");
  doc.text("Contact Us", sectionX + 5, sectionY + 10);
  // Add the first image
  doc.addImage(wedsiteLogo, "PNG", 10, 100, 10, 10);
  // Add the first text
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("www.bedfordhindutemple.org", 30, 105);
  // Add the second image
  doc.addImage(emailLogo, "PNG", 100, 100, 10, 10);
  // Add the second text
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("bedfordhindutemple@gmail.com", 120, 105);
  // Add the first image
  doc.addImage(detailslogo, "PNG", 10, 120, 10, 10);
  // Add the first text
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("Registered Charity No. 1185539", 30, 125);
  // Add the second image
  doc.addImage(addressLogo, "PNG", 100, 120, 10, 10);
  // Add the second text
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text("No. 5, Peel Street, Bedford MK40 2HX", 120, 125);

  // Save the PDF
  if (`${props[0].pdf}` === "yes") {
    doc.save(`${props[0].FullName} ${props[0].Surname} declaration.pdf`);
  } 
  else{
    console.log("Condition is false. PDF not saved.");
  }
  // doc.save(`${props[0].FullName} ${props[0].Surname} BHTDeclaration.pdf`);
  const pdfContent = doc.output("datauristring");
  console.log("pdfContent", pdfContent);
  window.localStorage.setItem("pdfContent", pdfContent);
  const formData = {
    from: "bhtportal@gmail.com",
    to: [`${props[0].EmailId},bedfordhindutemple@gmail.com`],
    // to:[`${props[0].EmailId},`],
    subject: `Thanks ${props[0].FullName} ${props[0].Surname} from Bedford Hindu Temple`,
    html: `<p><span style="font-family: Helvetica;">Dear ${props[0].FullName},</span></p>
        <p><span style="font-family: Helvetica;">We extend our heartfelt gratitude for your generous donation to the Bedford Hindu Temple. Your support will help us continue our mission of serving the community and promoting spiritual growth. Thank you for being a part of this noble cause.</span></p>
        <p><span style="font-family: Helvetica;">To download the declaration form, please find the attachment:</span></p>
        <p><span style="font-family: Helvetica;">Sincerely,&nbsp;</span></p>
        <p><span style="font-family: Helvetica;">Bedford Hindu Temple,</span></p>
        <p><span style="font-family: Helvetica;">Registered Charity No. 1185539,</span></p>
        <p><span style="font-family: Helvetica;">No. 5, Peel Street,&nbsp;</span></p>
        <p><span style="font-family: Helvetica;">Bedford MK40 2HX.</span></p>`,
    content: pdfContent.split(";base64,").pop(),
    filename: `${props[0].FullName} ${props[0].Surname} BHTDeclaration.pdf`,
  };
  const res = await axios.post(
    "https://dev-api.bookyourtime.co.uk/emailAPI",
    formData
  );
  console.log("res", res);
};
const PdfGenerator = (data) => {
  console.log("pdfgenereator", data);
  return generatePDF(data);
};

export default PdfGenerator;
